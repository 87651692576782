import React from "react";
import PropTypes from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { combine } from "../helper/classNames";

const Chip = ({
  filter,
  onChange,
  currentlySelected,
  isHorizontal,
  withCross,
}) => {
  return (
    <div
      role="button"
      className={combine(
        "chip",
        currentlySelected && "selected",
        !isHorizontal && "vertical",
        withCross && "withCross"
      )}
      onClick={() => onChange("blogSerie", filter)}
    >
      <span className="chipText">
        {filter.title}{" "}
        {currentlySelected && withCross && (
          <FontAwesomeIcon className="crossIcon" icon={faTimes} />
        )}
      </span>
    </div>
  );
};

Chip.propTypes = {
  withCross: PropTypes.bool,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  currentlySelected: PropTypes.bool,
  isHorizontal: PropTypes.bool,
};
Chip.defaultProps = {
  withCross: false,
  filter: {},
  currentlySelected: false,
  isHorizontal: false,
};

export default Chip;
