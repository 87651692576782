import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql } from "gatsby";
import * as _ from "lodash";
import "moment/locale/de";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { renderDynamicComponents } from "../components";
import CarouselControl from "../components/carouselControl";
import CarouselDot from "../components/carouselDot";
import TeaserCard from "../components/teaserCard";
import { YOUTUBE_CHANNEL_URL } from "../constants";
import { carouselBreakpoints } from "../helper/carousel";
import Link, {
  getArticleLink,
  getLocalizedLink,
  getPublicationLink,
} from "../helper/link";
import { getCappedText, getNormalizedReader } from "../helper/normalizer";
import { dtrOptions } from "../helper/richText";
import { getLocalizedTags, getValue } from "../helper/translation";
import Layout from "../modules/layout";
import SEO from "../modules/seo";
import Chip from "../components/chip";
import useWindowSize from "../hooks/useWindowSize";
import cookiesContext from "../context/cookies/cookiesContext";
import CookiesAreNecessary from "../components/cookiesAreNecessary";
import MicrocopyContext from "../context/microcopy/microcopyContext";

const CAPPED_TEXT_LENGTH = 160;

const HomePage = ({
  data: {
    allContentfulBlogArticle,
    allContentfulNewsArticle,
    allContentfulImpulseArticle,
    allContentfulPublication,
    allContentfulShortMessage2,
    allContentfulEmbeddedMedia,
  },
  pageContext,
}) => {
  const { name, contentComponents, node_locale, settings } = pageContext;
  const { microcopy } = settings;
  const { cookies } = useContext(cookiesContext);
  const marketingCookies = cookies[2];
  const [selectedBlogChip, setSelectedBlogChip] = useState(
    getValue("label.carouselTabAll", microcopy)
  );
  const [filteredBlogArticles, setFilteredBlogArticles] = useState([]);
  const [
    filteredBlogSectionArticles,
    setFilteredBlogSectionArticles,
  ] = useState([]);
  const [selectedTab, setSelectedTab] = useState("home");
  const { width } = useWindowSize();
  const carouselRef = useRef(null);
  useEffect(() => {
    setSelectedBlogChip(selectedBlogChip);
  }, [selectedTab]);

  useEffect(() => {
    if (carouselRef) {
      carouselRef.current.goToSlide(0, true);
    }
  }, [selectedBlogChip]);

  const allNewsArticle = allContentfulNewsArticle.edges.map(edge => ({
    ...edge.node.articleDetail,
    generalTitle: edge.node.title,
  }));
  const allBlogArticle = allContentfulBlogArticle.edges.map(edge => ({
    ...edge.node.articleDetail,
    generalTitle: edge.node.title,
    blogSerie: edge.node.blogSeries,
  }));

  const blogSeries = allContentfulBlogArticle.edges
    .map(edge => edge.node?.blogSeries)
    .filter(Boolean);
  const uniqueBlogSeries = blogSeries.filter(
    (v, i, a) => a.findIndex(t => t.id === v.id) === i
  );
  const sortedFilters = uniqueBlogSeries.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  const allShortMessage = allContentfulShortMessage2.edges;
  const impulseArticle = allContentfulImpulseArticle.edges.map(edge => ({
    ...edge.node.articleDetail,
    generalTitle: edge.node.title,
  }))[0];
  const allPublicationDetail = allContentfulPublication.edges.map(edge => ({
    ...edge.node.publicationDetail,
    generalTitle: edge.node.title,
  }));
  const embeddedMedia =
    allContentfulEmbeddedMedia.edges &&
    allContentfulEmbeddedMedia.edges[0] &&
    allContentfulEmbeddedMedia.edges[0].node;
  const siteSettings = settings;

  const responsive = {
    xl: {
      breakpoint: carouselBreakpoints.xl,
      items: 1,
    },
    l: {
      breakpoint: carouselBreakpoints.l,
      items: 1,
    },
    m: {
      breakpoint: carouselBreakpoints.m,
      items: 1,
    },
    s: {
      breakpoint: carouselBreakpoints.s,
      items: 1,
    },
    xs: {
      breakpoint: carouselBreakpoints.xs,
      items: 1,
    },
  };
  const responsiveBlogSeries = {
    xl: {
      breakpoint: carouselBreakpoints.xl,
      slidesToSlide: 3,
      items: 3,
    },
    l: {
      breakpoint: carouselBreakpoints.l,
      slidesToSlide: 3,
      items: 3,
    },
    m: {
      breakpoint: carouselBreakpoints.m,
      slidesToSlide: 2,
      items: 2,
    },
    s: {
      breakpoint: carouselBreakpoints.s,
      items: 1,
    },
    xs: {
      breakpoint: carouselBreakpoints.xs,
      items: 1,
    },
  };

  useEffect(() => {
    if (selectedBlogChip === getValue("label.carouselTabAll", microcopy)) {
      setFilteredBlogArticles(allBlogArticle);
    } else {
      setFilteredBlogArticles(
        allBlogArticle.filter(
          blogArticle => blogArticle?.blogSerie?.title === selectedBlogChip
        )
      );
    }
  }, [selectedBlogChip]);

  const renderTeaserCard = article => (
    <TeaserCard
      title={article.articleTitle}
      image={article.imageLandscape.imageLandscape.fluid}
      content={getCappedText(
        getNormalizedReader(article.articleReader.json),
        CAPPED_TEXT_LENGTH
      )}
      tags={getLocalizedTags(
        article.visibleTags,
        [...article.topic, article.focusOfWork, article.region],
        article.node_locale
      )}
      createdAt={article.postDateTime}
      locale={node_locale}
      link={getArticleLink(article.generalTitle, article.node_locale)}
      type={article.type}
      readMoreLabelText={getValue("actions.readMore", microcopy)}
    ></TeaserCard>
  );

  const renderAllBlogArticles = type => {
    const articles =
      type === "section" ? filteredBlogSectionArticles : filteredBlogArticles;
    return articles?.slice(0, 3).map(article => renderTeaserCard(article));
  };

  const renderBlogArticles = (type, format) => {
    return format === "slider" ? (
      filteredBlogArticles.slice(0, 2).map(article => renderTeaserCard(article))
    ) : type === "section" ? (
      filteredBlogSectionArticles
        .slice(0, 8)
        .map(article => renderTeaserCard(article))
    ) : (
      <>
        <Row as={"div"} className="card-list">
          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="card-item">
            <TeaserCard
              title={filteredBlogArticles[0]?.blogSerie?.title}
              image={filteredBlogArticles[0]?.blogSerie?.image.image.fluid}
              content={getCappedText(
                filteredBlogArticles[0]?.blogSerie?.description,
                300
              )}
              locale={node_locale}
              readMoreLabelText={getValue("actions.showMore", microcopy)}
              isBlogSerie
            ></TeaserCard>
          </Col>
          {filteredBlogArticles.slice(0, 8).map(article => (
            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="card-item">
              {renderTeaserCard(article)}
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12} className={"d-flex justify-content-center mt-5"}>
            <Link
              to={getLocalizedLink(
                "/artikel?type=blog",
                siteSettings.node_locale
              )}
              className="button button--inverted button--link"
            >
              {getValue("actions.showAll", microcopy)}
            </Link>
          </Col>
        </Row>
      </>
    );
  };

  const renderBlogSeriesCard = type => {
    const articles =
      type === "section" ? filteredBlogSectionArticles : filteredBlogArticles;
    return (
      articles.length && (
        <TeaserCard
          title={articles[0]?.blogSerie?.title}
          image={articles[0]?.blogSerie?.image.image.fluid}
          content={getCappedText(
            articles[0]?.blogSerie?.description,
            CAPPED_TEXT_LENGTH
          )}
          locale={node_locale}
          link={getLocalizedLink(
            `/artikel?type=blog&blogSerie=${articles[0].blogSerie?.id}`,
            articles[0].node_locale
          )}
          readMoreLabelText={getValue("actions.showMore", microcopy)}
          isBlogSerie
        ></TeaserCard>
      )
    );
  };

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
        ogImage={
          contentComponents.find(
            el => el.internal.type === "ContentfulComponentPageHeader"
          )?.image?.image?.fluid.src
        }
      />
      {contentComponents &&
        renderDynamicComponents(contentComponents, node_locale)}

      <Container fluid className="d-flex homePage">
        <Row as="section" className="section-head-container">
          <Col xs={12} lg={8}>
            <h2 className="headline">
              {getValue("labels.impulseArticle", microcopy)}
            </h2>
            <span className="sub-headline">
              {getValue("labels.impulseArticle.copy", microcopy)}
            </span>
          </Col>
          <Col xs={12} lg={4} className="text-right with-button">
            <Link
              className={"home-read-more-button"}
              to={getLocalizedLink(
                `/artikel?type=impuls`,
                siteSettings.node_locale
              )}
            >
              {getValue("actions.seeAll.impulseArticle", microcopy)}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {impulseArticle && (
              <TeaserCard
                title={impulseArticle.articleTitle}
                className={"impulse"}
                big={true}
                image={impulseArticle.imageLandscape.imageLandscape.fluid}
                content={getCappedText(
                  getNormalizedReader(impulseArticle.articleReader.json),
                  CAPPED_TEXT_LENGTH
                )}
                tags={getLocalizedTags(
                  impulseArticle.visibleTags,
                  [
                    ...impulseArticle.topic,
                    impulseArticle.focusOfWork,
                    impulseArticle.region,
                  ],
                  impulseArticle.node_locale
                )}
                createdAt={impulseArticle.postDateTime}
                locale={node_locale}
                landscape={true}
                type={impulseArticle.type}
                link={getArticleLink(
                  impulseArticle.generalTitle,
                  impulseArticle.node_locale
                )}
                readMoreLabelText={getValue("actions.readMore", microcopy)}
              />
            )}
          </Col>
        </Row>
        <Row as="section" className="section-head-container">
          <Col xs={12} lg={8}>
            <h2 className="headline">
              {getValue("labels.currentTopics", microcopy)}
            </h2>
            <span className="sub-headline">
              {getValue("labels.currentTopics.copy", microcopy)}
            </span>
          </Col>
          <Col xs={12} lg={4} className="text-right with-button">
            <Link
              className="home-read-more-button"
              to={getLocalizedLink(
                `/artikel?type=aktuelles`,
                siteSettings.node_locale
              )}
            >
              {getValue("actions.seeAll.currentTopics", microcopy)}
            </Link>
          </Col>
        </Row>
        <div className="carousel-container">
          <Carousel
            ref={carouselRef}
            responsive={responsiveBlogSeries}
            swipeable={true}
            draggable={true}
            showDots={width < carouselBreakpoints.m.max}
            containerClass="frient-carousel article-gallery homeCarousel"
            itemClass="blogSeriesItem"
            renderDotsOutside
            dotListClass="frient-carousel-dots centered-dots"
            arrows={false}
          >
            {allNewsArticle &&
              allNewsArticle
                .slice(0, 3)
                .map(article => (
                  <TeaserCard
                    title={article.articleTitle}
                    image={article.imageLandscape.imageLandscape.fluid}
                    content={getCappedText(
                      getNormalizedReader(article.articleReader.json),
                      CAPPED_TEXT_LENGTH
                    )}
                    tags={getLocalizedTags(
                      article.visibleTags,
                      [...article.topic, article.focusOfWork, article.region],
                      article.node_locale
                    )}
                    createdAt={article.postDateTime}
                    locale={node_locale}
                    link={getArticleLink(
                      article.generalTitle,
                      article.node_locale
                    )}
                    type={article.type}
                    readMoreLabelText={getValue("actions.readMore", microcopy)}
                  ></TeaserCard>
                ))}
          </Carousel>
        </div>
        <Row as="section">
          <Col xs={12} lg={8}>
            <h2 className="headline">{getValue("labels.blog", microcopy)}</h2>
            <span className="sub-headline">
              {getValue("labels.blog.copy", microcopy)}
            </span>
          </Col>
          <div className="carousel-actions-container">
            <div className="carouselFilters">
              <Chip
                filter={{
                  title: getValue("label.carouselTabAll", microcopy),
                }}
                onChange={(_, filter) => setSelectedBlogChip(filter.title)}
                currentlySelected={
                  selectedBlogChip ===
                  getValue("label.carouselTabAll", microcopy)
                }
                isHorizontal
              />
              {sortedFilters.map(item => (
                <Chip
                  filter={{ title: item.title }}
                  onChange={(_, filter) => setSelectedBlogChip(filter.title)}
                  currentlySelected={item.title === selectedBlogChip}
                  isHorizontal
                />
              ))}
            </div>
            <div className="text-right">
              <Link
                className="home-read-more-button"
                to={getLocalizedLink(
                  `/artikel?type=aktuelles`,
                  siteSettings.node_locale
                )}
              >
                {getValue("actions.seeAll.blog", microcopy)}
              </Link>
            </div>
          </div>
        </Row>
        <div className="carousel-container">
          <Carousel
            ref={carouselRef}
            responsive={responsiveBlogSeries}
            swipeable={true}
            draggable={true}
            showDots={width < carouselBreakpoints.m.max}
            containerClass="frient-carousel article-gallery homeCarousel"
            itemClass="blogSeriesItem"
            renderDotsOutside
            dotListClass="frient-carousel-dots centered-dots"
            arrows={false}
          >
            {selectedBlogChip === getValue("label.carouselTabAll", microcopy)
              ? renderAllBlogArticles()
              : [renderBlogSeriesCard(), ...renderBlogArticles(_, "slider")]}
          </Carousel>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={4} xl={4} className={"news-section"}>
            <Row as="section">
              <Col xs={12} as={"h6"}>
                {getValue("labels.news", microcopy)}
              </Col>
            </Row>
            <Row className="news-container">
              <Col xs={12}>
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  infinite={false}
                  className={"bg-white h-100"}
                  containerClass="frient-carousel align-items-start"
                  customButtonGroup={<CarouselControl />}
                  arrows={false}
                  dots={false}
                  image={""}
                  dotListClass="frient-carousel-dots"
                  customDot={<CarouselDot />}
                >
                  {allShortMessage.map(
                    ({ node: shortMessage } = shortMessage) => (
                      <TeaserCard
                        title={shortMessage.title}
                        content={documentToReactComponents(
                          shortMessage.content.json,
                          dtrOptions
                        )}
                        articleType={shortMessage.type.name}
                      ></TeaserCard>
                    )
                  )}
                </Carousel>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={8}
            xl={8}
            className={"publications-section"}
          >
            <Row as="section">
              <Col xs={6} as={"h6"}>
                {getValue("labels.publications", microcopy)}
              </Col>
              <Col xs={6} className="text-right top">
                <Link
                  className={"home-read-more"}
                  to={getLocalizedLink(
                    `/publikationen`,
                    siteSettings.node_locale
                  )}
                >
                  {getValue("actions.seeAll", microcopy)}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="lg"
                    className="home-read-more-icon"
                  />
                </Link>
              </Col>
            </Row>
            <Row className="publications-container">
              {allPublicationDetail.map(publication => (
                <Col xs={12} className="mb-3 mb-sm-0" key={publication.id}>
                  <TeaserCard
                    title={publication.title}
                    image={
                      publication.imageSquare.imageSquare
                        ? publication.imageSquare.imageSquare.fluid
                        : publication.imageLandscape.imageLandscape.fluid
                    }
                    content={getCappedText(
                      getNormalizedReader(publication.reader.json),
                      CAPPED_TEXT_LENGTH
                    )}
                    tags={getLocalizedTags(
                      publication.visibleTags,
                      [
                        ...publication.topic,
                        publication.focusOfWork,
                        publication.region,
                      ],
                      publication.node_locale
                    )}
                    landscape
                    link={getPublicationLink(
                      publication.generalTitle,
                      publication.node_locale
                    )}
                    file={publication?.document?.file?.url}
                    createdAt={publication.publicationDateTime}
                    locale={node_locale}
                    readMoreLabelText={getValue("actions.readMore", microcopy)}
                    downloadLabelText={getValue("actions.download", microcopy)}
                  ></TeaserCard>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Row as="section">
              <Col xs={6} as={"h6"}>
                {getValue("labels.videos", microcopy)}
              </Col>
              {marketingCookies && (
                <Col xs={6} className="text-right top">
                  <Link
                    to={{
                      url: YOUTUBE_CHANNEL_URL,
                    }}
                    className="home-read-more"
                  >
                    {getValue("actions.seeAll", microcopy)}

                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="lg"
                      className="home-read-more-icon"
                    />
                  </Link>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12}>
                {embeddedMedia && (
                  <MicrocopyContext.Provider value={siteSettings.microcopy}>
                    <TeaserCard
                      video={embeddedMedia.embedLink}
                      title={embeddedMedia.title}
                      content={embeddedMedia.reader}
                      tags={getLocalizedTags(
                        embeddedMedia.visibleTags,
                        [...embeddedMedia.topic],
                        embeddedMedia.node_locale
                      )}
                      image={
                        embeddedMedia.thumbnailLandscape.imageLandscape.fluid
                      }
                      createdAt={embeddedMedia.postDateTime}
                      locale={node_locale}
                      landscape
                      big
                    />
                  </MicrocopyContext.Provider>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($node_locale: String!) {
    allContentfulShortMessage2(
      limit: 4
      filter: { node_locale: { eq: $node_locale } }
      sort: { order: DESC, fields: publicationDateTime }
    ) {
      edges {
        node {
          type {
            name
          }
          title
          publicationDateTime
          content {
            json
          }
        }
      }
    }
    allContentfulBlogArticle: allContentfulArticleGeneral(
      filter: {
        articleDetail: {
          typeOfArticle: { contentful_id: { eq: "blog" } }
          node_locale: { eq: $node_locale }
        }
      }
      sort: { order: DESC, fields: articleDetail___postDateTime }
    ) {
      ...articleDetailFields
    }
    allContentfulImpulseArticle: allContentfulArticleGeneral(
      filter: {
        articleDetail: {
          typeOfArticle: { contentful_id: { eq: "impuls" } }
          node_locale: { eq: $node_locale }
        }
      }
      limit: 1
      sort: { order: DESC, fields: articleDetail___postDateTime }
    ) {
      ...articleDetailFields
    }
    allContentfulNewsArticle: allContentfulArticleGeneral(
      filter: {
        articleDetail: {
          typeOfArticle: { contentful_id: { eq: "aktuelles" } }
          node_locale: { eq: $node_locale }
        }
      }
      limit: 9
      sort: { order: DESC, fields: articleDetail___postDateTime }
    ) {
      ...articleDetailFields
    }
    allContentfulPublication: allContentfulPublicationGeneral(
      limit: 1
      filter: { publicationDetail: { node_locale: { eq: $node_locale } } }
      sort: { order: DESC, fields: publicationDetail___publicationDateTime }
    ) {
      ...publicationDetailFields
    }
    allContentfulEmbeddedMedia(
      limit: 1
      sort: { order: DESC, fields: postDateTime }
    ) {
      edges {
        node {
          title
          reader
          embedLink
          visibleTags
          organization {
            name
          }
          topic {
            contentful_id
            name
          }
          postDateTime
          thumbnailSquare {
            imageSquare {
              fluid {
                srcSet
                src
                base64
                srcSetWebp
                srcWebp
              }
            }
          }
          thumbnailLandscape {
            imageLandscape {
              fluid {
                srcSet
                src
                base64
                srcSetWebp
                srcWebp
              }
            }
          }
          node_locale
        }
      }
    }
  }

  fragment publicationDetailFields on ContentfulPublicationGeneralConnection {
    edges {
      node {
        title
        publicationDetail {
          id
          visibleTags
          node_locale
          reader {
            json
            content {
              content {
                value
              }
            }
          }
          organizations {
            name
          }
          focusOfWork {
            contentful_id
            name
          }
          topic {
            contentful_id
            name
          }
          region {
            contentful_id
            name
          }
          title
          publicationDateTime
          document {
            title
            file {
              url
            }
          }
          imageSquare {
            imageSquare {
              fluid {
                srcSet
                src
                base64
                srcSetWebp
                srcWebp
              }
            }
          }
          imageLandscape {
            imageLandscape {
              fluid {
                srcSet
                src
                base64
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  }

  fragment articleDetailFields on ContentfulArticleGeneralConnection {
    edges {
      node {
        title
        articleDetail {
          id
          node_locale
          organization {
            name
          }
          postDateTime
          region {
            contentful_id
            name
            id
          }
          typeOfArticle {
            name
            id
          }
          articleTitle
          visibleTags
          articleSubtitle
          focusOfWork {
            contentful_id
            name
          }
          topic {
            contentful_id
            name
          }
          articleReader {
            json
            content {
              content {
                value
              }
            }
          }
          articleContent {
            content {
              content {
                value
              }
            }
          }
          authorOfPublication {
            name
          }
          imageLandscape {
            imageLandscape {
              fluid {
                srcSet
                src
                aspectRatio
                srcSetWebp
                srcWebp
              }
            }
          }
          imageSquare {
            imageSquare {
              fluid {
                srcSet
                src
                base64
                srcSetWebp
                srcWebp
              }
            }
          }
        }
        blogSeries {
          id
          contentful_id
          name
          title
          description
          image {
            image {
              fluid(quality: 100) {
                ...imageFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
